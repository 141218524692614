
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import TextControl from "@/components/controls/base/TextControl.vue";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";

export default defineComponent({
  components: {
    CreateEditDialog,
    DecimalControl,
    TextControl,
    IntegerControl,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  computed: {},
  data() {
    return {
      model: null,
      copyMode: false,
    };
  },
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
      this.model = this.dialog().createEditModel;
      this.setCopyMode(false);
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
    setCopyMode(enabled: any) {
      this.copyMode = enabled;
    },
  },
});
